import http from 'http.js';

import {loadUser} from 'accounts/actions/accountsActions.js';
import {
    ACCOUNTS_FEEDBACK_API,
    CONSULTATIONS_CHECK_API,
    CONSULTATIONS_CONSULTATIONS_API,
} from 'apiEndpoints.js';
import batchActions from 'better_consult/actions/batchActions.js';
import actions from 'better_consult/actions/types.js';
import {getPatchData} from 'better_consult/actions/utils.js';
import {pushHistory} from 'better_consult/components/History.js';
import {
    getConsultationProblems,
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_FREE_TEXT,
    PROBLEM_TYPE_PRESCRIPTION,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {
    addInitialDefaultSteps,
    addStep,
    addStepsForFreeTextProblem,
    addStepsForSymptom,
    addStepsForMedicationStep,
} from 'consultations/actions/formActions.js';
import {status} from 'consultations/helpers.js';
import {YES} from 'questions/components/ScaleQuestion.js';
import {
    camelToSnake,
    snakeToCamel,
    snakeToCamelObjectKeys,
} from 'utils/case_converter.js';

export function submitSymptom(
    {stepId, symptomId, displayText, medicationCourseId},
    reload = false,
) {
    return async function (dispatch, getState) {
        const {consultationSymptoms} = getState().data.consultations;
        const consultationSymptom = Object.values(consultationSymptoms).find(
            (value) => value.symptomId === symptomId,
        );
        let shouldAddSteps = true;
        let problem;
        if (reload) {
            ({problem, shouldAddSteps} = getState().form.steps.find(
                (step) => step.id === stepId,
            ).component.props);
        } else {
            let symptomName;
            let consultationSymptomId;

            // use ConsultationSymptom if symptom already exists
            if (consultationSymptom) {
                const symptom = getState().data.clinical.symptoms[
                    consultationSymptom.symptomId
                ];
                symptomName = symptom.name;
                consultationSymptomId = consultationSymptom.id;
                shouldAddSteps = false;
            } else {
                const {id} = getState().data.consultations.consultation;
                const data = {
                    'symptom': symptomId,
                    'display_text': displayText,
                };
                if (medicationCourseId) {
                    data[
                        'presenting_consultation_medication_course'
                    ] = medicationCourseId;
                }
                let response = await http.post({
                    url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/symptoms/`,
                    data,
                });
                response = snakeToCamelObjectKeys(response);
                symptomName = response.symptom.name;
                consultationSymptomId = response.id;

                // update clinical store with symptom data
                dispatch({
                    type: actions.data.ADD_CLINICAL_SYMPTOM,
                    payload: response.symptom,
                });
                response.symptomId = symptomId;
                delete response.symptom;
                // update consultation symptoms store with consultation symptom
                // data
                dispatch({
                    type: actions.data.ADD_CONSULTATION_SYMPTOM,
                    payload: response,
                });
                // update list of consultation symptoms on consultation store
                dispatch({
                    type: actions.data.SET_CONSULTATION_ATTR,
                    payload: {
                        field: 'consultationSymptoms',
                        value: [response.id],
                        merge: true,
                    },
                });
            }

            problem = {
                type: 'symptom',
                displayText: displayText || symptomName,
                symptomId,
                consultationSymptomId,
            };

            // set problem prop on the step within form store
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: problem,
                },
            });
        }
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.submittedSymptom',
                value: symptomId,
            },
        });
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.shouldAddSteps',
                value: shouldAddSteps,
            },
        });
        // skip symptom step if symptom already exists
        if (shouldAddSteps) {
            dispatch(addStepsForSymptom({stepId, problem}, reload));
        }
    };
}

export function submitCondition(
    {
        stepId,
        conditionId,
        displayText,
        isPresentingProblem = true,
        medicationCourseId,
    },
    reload = false,
) {
    return async function (dispatch, getState) {
        let problem;
        let condition;
        let shouldAddSteps = true;
        const {pastConditions} = getState().data.consultations;
        let pastCondition = Object.values(pastConditions).find(
            (value) => value.conditionId === conditionId,
        );
        if (reload) {
            ({problem, shouldAddSteps} = getState().form.steps.find(
                (step) => step.id === stepId,
            ).component.props);
            const {conditionId} = problem;
            condition = getState().data.clinical.conditions[conditionId];
        } else {
            // if past condition exists, use past condition and
            // don't go through condition workflow
            if (pastCondition) {
                shouldAddSteps = false;
            } else {
                const response = await dispatch(
                    createPastConditions(
                        [{id: conditionId, displayText}],
                        isPresentingProblem,
                        medicationCourseId,
                    ),
                );
                pastCondition = response[0];
            }
            condition = getState().data.clinical.conditions[
                pastCondition.conditionId
            ];
            problem = {
                type: 'condition',
                displayText: displayText || condition.name,
                conditionId,
                pastConditionId: pastCondition.id,
            };
            // set problem prop on the step within form store
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: problem,
                },
            });
        }
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.submittedCondition',
                value: conditionId,
            },
        });
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.shouldAddSteps',
                value: shouldAddSteps,
            },
        });
        // skip condition flow if condition isReasonForVisit is false
        // and shouldAddSteps is false
        if (condition.isReasonForVisit && shouldAddSteps) {
            dispatch(
                addStep({
                    type: 'PastConditionHasBeenDiagnosedStep',
                    props: {problem},
                    parentId: stepId,
                }),
            );
        }
    };
}

export function submitFreeTextProblem({stepId, text}, reload = false) {
    return async function (dispatch, getState) {
        let problem;
        if (reload) {
            ({problem} = getState().form.steps.find(
                (step) => step.id === stepId,
            ).component.props);
        } else {
            const {id} = getState().data.consultations.consultation;
            let response = await http.post({
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/symptoms/`,
                data: {
                    // eslint-disable-next-line camelcase
                    free_text_symptom: text,
                },
            });
            response = snakeToCamelObjectKeys(response);

            problem = {
                type: PROBLEM_TYPE_FREE_TEXT,
                displayText: text,
                freeTextProblemId: response.id,
            };

            // update consultation symptoms store with consultation symptom
            // data
            dispatch({
                type: actions.data.ADD_CONSULTATION_SYMPTOM,
                payload: response,
            });
            // update list of consultation symptoms on consultation store
            dispatch({
                type: actions.data.SET_CONSULTATION_ATTR,
                payload: {
                    field: 'consultationSymptoms',
                    value: [response.id],
                    merge: true,
                },
            });
            // set problem prop on the step within form store
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: problem,
                },
            });
        }
        dispatch(addStepsForFreeTextProblem({stepId, problem}));
    };
}

export function removeConsultationSymptom(csid, successActions = []) {
    return async function (dispatch, getState) {
        const cid = getState().data.consultations.consultation.id;
        await http.delete({
            url: `${CONSULTATIONS_CONSULTATIONS_API}${cid}/symptoms/${csid}/`,
        });

        // actions are synchronous so we can batch them
        dispatch(
            batchActions(...successActions, {
                type: actions.data.REMOVE_CONSULTATION_SYMPTOM,
                payload: {id: csid},
            }),
        );
    };
}

export function removeConsultationSymptomV2(
    csid,
    successActions = [],
    shouldCleanUpStore = false,
) {
    return async function (dispatch, getState) {
        const cid = getState().data.consultations.consultation.id;
        await http.delete({
            url: `${CONSULTATIONS_CONSULTATIONS_API}${cid}/symptoms/${csid}/`,
        });

        if (shouldCleanUpStore) {
            // actions are synchronous so we can batch them
            dispatch(
                batchActions(...successActions, {
                    type: actions.data.REMOVE_CONSULTATION_SYMPTOM,
                    payload: {id: csid},
                }),
            );
        }

        dispatch({
            type: actions.data.REMOVE_RELATED_SYMPTOM,
            payload: csid,
        });
    };
}

export function undoSymptom(stepId) {
    return async function (dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {submittedSymptom} = step.component.props;
        if (submittedSymptom) {
            const {
                consultationSymptoms,
            } = store.data.consultations.consultation;
            const {
                symptomId,
                consultationSymptomId,
            } = step.component.props.problem;

            await dispatch(
                removeConsultationSymptom(consultationSymptomId, [
                    {
                        type: actions.data.SET_CONSULTATION_ATTR,
                        payload: {
                            field: 'consultationSymptoms',
                            value: consultationSymptoms.filter(
                                (item) => item !== consultationSymptomId,
                            ),
                        },
                    },
                ]),
            );
            dispatch({
                type: actions.data.REMOVE_CLINICAL_SYMPTOM,
                payload: {id: symptomId},
            });
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: undefined,
                },
            });
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.submittedSymptom',
                    value: undefined,
                },
            });
        }
    };
}

export function undoCondition(stepId) {
    return async function (dispatch, getState) {
        const step = getState().form.steps.find((step) => step.id === stepId);
        const {submittedCondition} = step.component.props;
        if (submittedCondition) {
            const {pastConditionId} = step.component.props.problem;
            await dispatch(deletePastConditions([pastConditionId]));
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: undefined,
                },
            });
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.submittedCondition',
                    value: undefined,
                },
            });
        }
    };
}

export function undoFreeTextProblem(stepId) {
    return async function (dispatch, getState) {
        const store = getState();
        const {
            id,
            consultationSymptoms,
        } = store.data.consultations.consultation;
        const step = store.form.steps.find((step) => step.id === stepId);
        const {freeTextProblemId} = step.component.props.problem;
        await http.delete({
            url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/symptoms/${freeTextProblemId}/`,
        });
        dispatch({
            type: actions.data.SET_CONSULTATION_ATTR,
            payload: {
                field: 'consultationSymptoms',
                value: consultationSymptoms.filter(
                    (item) => item !== freeTextProblemId,
                ),
            },
        });
        dispatch({
            type: actions.data.REMOVE_CONSULTATION_SYMPTOM,
            payload: {id: freeTextProblemId},
        });
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.problem',
                value: undefined,
            },
        });
    };
}

export function completeConsultation(id) {
    return async function (dispatch) {
        const data = {
            status: status.STATUS_COMPLETE,
        };
        const url = `${CONSULTATIONS_CONSULTATIONS_API}${id}/`;
        await http.patch({url, data});
        dispatch({
            type: actions.data.SET_CONSULTATION_ATTR,
            payload: {
                field: 'status',
                value: status.STATUS_COMPLETE,
            },
        });
    };
}

export function restoreConsultation(store) {
    return {
        type: actions.consultations.RESTORE_CONSULTATION_SUCCESS,
        payload: store,
    };
}

export function checkConsultationToken(token) {
    return async function (dispatch, getState) {
        let response;
        const loggedIn = getState().accounts.user.loggedIn;

        try {
            response = snakeToCamelObjectKeys(
                await http.post({
                    url: CONSULTATIONS_CHECK_API,
                    data: {token},
                }),
            );
        } catch (err) {
            const data = JSON.parse(err.message);
            if ([503, 504].includes(data.status)) {
                dispatch({
                    type: actions.data.CONSULTATION_CHECK_TOKEN_FAILED,
                    payload: 'apiUnavailable',
                });
                return;
            }
            const {error, redirectPath} = snakeToCamelObjectKeys(
                JSON.parse(data.response),
            );
            dispatch({
                type: actions.data.CONSULTATION_CHECK_TOKEN_FAILED,
                payload: snakeToCamel(error),
            });
            pushHistory(redirectPath, {preservedQueryKeys: ['token']});
            return;
        }
        if (response.clinical) {
            dispatch({
                type: actions.data.ADD_CONSTITUTIONAL_SYMPTOMS,
                payload: response.clinical.constitutionalSymptoms,
            });
            dispatch({
                type: actions.data.ADD_RECREATIONAL_DRUGS,
                payload: response.clinical.recreationalDrugs,
            });
        }
        dispatch({
            type: actions.data.CONSULTATION_CHECK_TOKEN_SUCCESS,
            payload: response.consultation,
            token,
        });
        await dispatch(loadUser(response.consultation.id));
        if (loggedIn) {
            let reload = false;
            if (response.store) {
                dispatch(restoreConsultation(response.store));
                reload = true;
            }
            dispatch(addInitialDefaultSteps(reload));
        }
        pushHistory(response.redirectPath, {preservedQueryKeys: ['token']});
    };
}

export function saveStore() {
    return async function (dispatch, getState) {
        const store = getState();
        const {data, form, questions} = store;
        const steps = form.steps.filter((step) => step.component.completed);
        const {progress} = form;
        const {id} = data.consultations.consultation;
        await http.patch({
            url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/store/`,
            data: {
                store: JSON.stringify({data, questions, steps, progress}),
            },
        });
    };
}

// symptomId is the clinical id of the related symptom
// symptomId is the clinical id of the related symptom
export function submitRelatedSymptom({symptomId, problem, constitutional}) {
    return async function (dispatch, getState) {
        const {id} = getState().data.consultations.consultation;
        const {consultationSymptomId, pastConditionId} = problem;

        const data = {symptom: symptomId};
        if (consultationSymptomId) {
            // eslint-disable-next-line camelcase, max-len
            data.presenting_consultation_symptom = consultationSymptomId;
        } else if (pastConditionId) {
            // eslint-disable-next-line camelcase, max-len
            data.presenting_consultation_past_condition = pastConditionId;
        }
        if (constitutional) {
            // eslint-disable-next-line camelcase
            data.is_constitutional = constitutional;
        }
        const response = snakeToCamelObjectKeys(
            await http.post({
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/symptoms/`,
                data,
            }),
        );

        dispatch({
            type: actions.data.ADD_CLINICAL_SYMPTOM,
            payload: response.symptom,
        });
        response.symptomId = symptomId;
        delete response.symptom;
        dispatch({
            type: actions.data.ADD_CONSULTATION_SYMPTOM,
            payload: response,
        });
        dispatch({
            type: actions.data.SET_CONSULTATION_SYMPTOM_ATTR,
            payload: {
                id: consultationSymptomId,
                field: 'relatedSymptoms',
                value: [response.id],
                merge: true,
            },
        });
        return response;
    };
}

// symptomId is the clinical id of the related symptom
export function submitRelatedSymptomV2({
    relatedSymptomCertainty,
    symptomId,
    problem,
    constitutional,
}) {
    return async function (dispatch, getState) {
        const {id} = getState().data.consultations.consultation;
        const {consultationSymptomId, pastConditionId} = problem;

        const data = {symptom: symptomId};
        if (consultationSymptomId) {
            // eslint-disable-next-line camelcase, max-len
            data.presenting_consultation_symptom = consultationSymptomId;
        } else if (pastConditionId) {
            // eslint-disable-next-line camelcase, max-len
            data.presenting_consultation_past_condition = pastConditionId;
        }
        if (constitutional) {
            // eslint-disable-next-line camelcase
            data.is_constitutional = constitutional;
        }
        if (relatedSymptomCertainty) {
            // eslint-disable-next-line camelcase
            data.related_symptom_certainty = relatedSymptomCertainty;
        }
        const response = snakeToCamelObjectKeys(
            await http.post({
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/symptoms/`,
                data,
            }),
        );

        if (relatedSymptomCertainty === YES) {
            // Only save related symptoms to the store that had a YES answer
            dispatch({
                type: actions.data.ADD_CLINICAL_SYMPTOM,
                payload: response.symptom,
            });
            response.symptomId = symptomId;
            delete response.symptom;
            dispatch({
                type: actions.data.ADD_CONSULTATION_SYMPTOM,
                payload: response,
            });
            dispatch({
                type: actions.data.SET_CONSULTATION_SYMPTOM_ATTR,
                payload: {
                    id: consultationSymptomId,
                    field: 'relatedSymptoms',
                    value: [response.id],
                    merge: true,
                },
            });
        }
        dispatch({
            type: actions.data.ADD_RELATED_SYMPTOM,
            payload: {
                csid: response.id,
                symptomId,
            },
        });
        return response;
    };
}

export function removeRelatedSymptom(rsid, presentingSymptomId) {
    return async function (dispatch, getState) {
        const consultationsData = getState().data.consultations;
        const {symptomId} = consultationsData.consultationSymptoms[rsid];
        const successActions = [
            {
                // eslint-disable-next-line max-len
                type:
                    actions.data
                        .REMOVE_RELATED_SYMPTOM_FROM_PRESENTING_SYMPTOM,
                payload: {
                    id: presentingSymptomId,
                    relatedSymptomId: rsid,
                },
            },
            {
                type: actions.data.REMOVE_CLINICAL_SYMPTOM,
                payload: {id: symptomId},
            },
        ];
        await dispatch(removeConsultationSymptom(rsid, successActions));
    };
}

export function removeRelatedSymptomV2(rsid, presentingSymptomId) {
    return async function (dispatch, getState) {
        const consultationsData = getState().data.consultations;
        const cs = consultationsData.consultationSymptoms[rsid];
        let successActions = [];

        // If the consultation symptom (cs) is not in the store,
        // then it did not have a YES answer on the related symptom.
        const shouldCleanUpStore = cs !== undefined;
        if (shouldCleanUpStore) {
            successActions = [
                {
                    // eslint-disable-next-line max-len
                    type:
                        actions.data
                            .REMOVE_RELATED_SYMPTOM_FROM_PRESENTING_SYMPTOM,
                    payload: {
                        id: presentingSymptomId,
                        relatedSymptomId: rsid,
                    },
                },
                {
                    type: actions.data.REMOVE_CLINICAL_SYMPTOM,
                    payload: {id: cs.symptomId},
                },
            ];
        }
        await dispatch(
            removeConsultationSymptomV2(
                rsid,
                successActions,
                shouldCleanUpStore,
            ),
        );
    };
}

export function submitProblemQuestion(questionId, problem, answersToAdd) {
    return async function (dispatch, getState) {
        const store = getState();
        const cid = store.data.consultations.consultation.id;
        // eslint-disable-next-line max-len
        const url = `${CONSULTATIONS_CONSULTATIONS_API}${cid}/questions/${questionId}/`;
        switch (problem.type) {
            case PROBLEM_TYPE_SYMPTOM:
                await http.post({
                    url,
                    data: {
                        answers: answersToAdd,
                        'consultation_symptom': problem.consultationSymptomId,
                    },
                });
                dispatch({
                    type: actions.data.SUBMIT_SYMPTOM_QUESTION_SUCCESS,
                    payload: {
                        id: problem.consultationSymptomId,
                        qid: questionId,
                        value: answersToAdd,
                    },
                });
                break;
            case PROBLEM_TYPE_CONDITION:
                await http.post({
                    url,
                    data: {
                        answers: answersToAdd,
                        'consultation_past_condition': problem.pastConditionId,
                    },
                });
                dispatch({
                    type: actions.data.SUBMIT_CONDITION_QUESTION_SUCCESS,
                    payload: {
                        id: problem.pastConditionId,
                        qid: questionId,
                        value: answersToAdd,
                    },
                });
                break;
            case PROBLEM_TYPE_PRESCRIPTION:
                await http.post({
                    url,
                    data: {
                        answers: answersToAdd,
                        'consultation_medication_course':
                            problem.medicationCourseId,
                    },
                });
                dispatch({
                    type: actions.data.SUBMIT_MEDICATION_QUESTION_SUCCESS,
                    payload: {
                        id: problem.medicationCourseId,
                        qid: questionId,
                        value: answersToAdd,
                    },
                });
                break;
        }
    };
}

export function undoProblemQuestion(questionId, problem) {
    return async function (dispatch, getState) {
        const store = getState();
        const {id} = store.data.consultations.consultation;
        // eslint-disable-next-line max-len
        const url = `${CONSULTATIONS_CONSULTATIONS_API}${id}/questions/${questionId}/`;

        await http.delete({url});
        switch (problem.type) {
            case PROBLEM_TYPE_SYMPTOM:
                dispatch({
                    type: actions.data.UNDO_SUBMIT_SYMPTOM_QUESTION_SUCCESS,
                    payload: {
                        id: problem.consultationSymptomId,
                        qid: questionId,
                    },
                });
                break;
            case PROBLEM_TYPE_CONDITION:
                dispatch({
                    type: actions.data.UNDO_SUBMIT_CONDITION_QUESTION_SUCCESS,
                    payload: {
                        id: problem.pastConditionId,
                        qid: questionId,
                    },
                });
                break;
            case PROBLEM_TYPE_PRESCRIPTION:
                dispatch({
                    type: actions.data.UNDO_SUBMIT_MEDICATION_QUESTION_SUCCESS,
                    payload: {
                        id: problem.medicationCourseId,
                        qid: questionId,
                    },
                });
                break;
        }
    };
}

/**
 * This is a generic updater for non-flattened fields
 */
export function updateConsultationSymptom(
    id,
    {field, toReplace, toAdd, toRemove},
) {
    return async function (dispatch, getState) {
        const store = getState();
        const cid = store.data.consultations.consultation.id;
        const value = store.data.consultations.consultationSymptoms[id][field];
        const data = getPatchData({value, toReplace, toAdd, toRemove});
        const response = snakeToCamelObjectKeys(
            await http.patch({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${cid}/symptoms/${id}/`,
                data: {
                    [camelToSnake(field)]: data,
                },
            }),
        );

        dispatch({
            type: actions.data.SET_CONSULTATION_SYMPTOM_ATTR,
            payload: {id, field, value: response[field]},
        });
    };
}

/**
 * This is a generic updater for non-flattened fields
 */
export function updateConsultation({field, toReplace, toAdd, toRemove}) {
    return async function (dispatch, getState) {
        const consultation = getState().data.consultations.consultation;
        const value = consultation[field];
        const data = getPatchData({value, toReplace, toAdd, toRemove});
        const response = snakeToCamelObjectKeys(
            await http.patch({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${consultation.id}/`,
                data: {
                    [camelToSnake(field)]: data,
                },
            }),
        );

        dispatch({
            type: actions.data.SET_CONSULTATION_ATTR,
            payload: {field, value: response[field]},
        });
    };
}

/**
 * This is a generic updater for non-flattened fields
 */
export function updatePastCondition(id, {field, toReplace, toAdd, toRemove}) {
    return async function (dispatch, getState) {
        const state = getState();
        const cid = state.data.consultations.consultation.id;
        const value = state.data.consultations.pastConditions[id][field];
        const data = getPatchData({value, toReplace, toAdd, toRemove});
        let response = await http.patch({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${cid}/past-conditions/${id}/`,
            data: {
                [camelToSnake(field)]: data,
            },
        });
        response = snakeToCamelObjectKeys(response);

        dispatch({
            type: actions.data.SET_PAST_CONDITION_ATTR,
            payload: {id, field, value: response[field]},
        });
    };
}

/**
 * This is a generic updater for non-flattened fields
 */
export function updateMedicationCourse(
    id,
    {field, toReplace, toAdd, toRemove},
) {
    return async function (dispatch, getState) {
        const {consultation} = getState().data.consultations;
        const value = getState().data.consultations.medicationCourses[id][
            field
        ];
        const data = getPatchData({value, toReplace, toAdd, toRemove});
        const response = snakeToCamelObjectKeys(
            await http.patch({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${consultation.id}/medication-courses/${id}/`,
                data: {
                    [camelToSnake(field)]: data,
                },
            }),
        );

        dispatch({
            type: actions.data.SET_MEDICATION_COURSE_ATTR,
            payload: {id, field, value: response[field]},
        });
    };
}

export function createPastConditions(
    conditions,
    isPresentingProblem = false,
    medicationCourseId,
) {
    return async function (dispatch, getState) {
        const {consultation} = getState().data.consultations;
        let response = await http.post({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${consultation.id}/past-conditions/`,
            data: {
                'past_conditions': conditions.map(({id, displayText}) => ({
                    condition: id,
                    'display_text': displayText,
                    // eslint-disable-next-line camelcase
                    is_presenting_problem: isPresentingProblem,
                    'medication_course': medicationCourseId,
                })),
            },
        });

        response = response.map(snakeToCamelObjectKeys);
        // update clinical store with condition data
        for (const pastCondition of response) {
            dispatch({
                type: actions.data.ADD_CLINICAL_CONDITION,
                payload: pastCondition.condition,
            });
            pastCondition.conditionId = pastCondition.condition.id;
            delete pastCondition.condition;
        }
        // update past conditions store with past condition data
        dispatch({
            type: actions.data.ADD_PAST_CONDITIONS,
            payload: response,
        });
        // update list of past conditions on consultation store
        dispatch({
            type: actions.data.SET_CONSULTATION_ATTR,
            payload: {
                field: 'pastConditions',
                value: response.map((item) => item.id),
                merge: true,
            },
        });
        return response;
    };
}

export function deletePastConditions(pastConditions) {
    return async function (dispatch, getState) {
        const {consultation} = getState().data.consultations;
        const idsParams = http.parameterise({id: pastConditions});
        await http.delete({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${consultation.id}/past-conditions/?${idsParams}`,
        });

        dispatch({
            type: actions.data.SET_CONSULTATION_ATTR,
            payload: {
                field: 'pastConditions',
                value: consultation.pastConditions.filter(
                    (id) => !pastConditions.includes(id),
                ),
            },
        });
        for (const pastConditionId of pastConditions) {
            const pastCondition = getState().data.consultations.pastConditions[
                pastConditionId
            ];
            dispatch({
                type: actions.data.REMOVE_CLINICAL_CONDITION,
                payload: {id: pastCondition.conditionId},
            });
        }
        dispatch({
            type: actions.data.REMOVE_PAST_CONDITIONS,
            payload: {ids: pastConditions},
        });
    };
}

export function getStepAndProblemData(isUndo = false) {
    return function (dispatch, getState) {
        const store = getState();
        const steps = store.form.steps;
        const problems = getConsultationProblems(store);
        const incompleteStepIndex = steps.findIndex(
            (step) => !step.component.completed,
        );
        const currentStepIndex = isUndo
            ? incompleteStepIndex - 1
            : incompleteStepIndex;
        const currentStep = steps[currentStepIndex];
        const conId = store.data.consultations.consultation.id;
        return {
            steps: steps.map((step) => `${step.component.type}`),
            // eslint-disable-next-line camelcase
            current_step: {
                id: currentStep.id,
                completed: currentStep.component.completed,
                type: currentStep.component.type,
            },
            // eslint-disable-next-line camelcase
            consultation_id: conId,
            problems: problems.map(
                (problem) =>
                    `id: ${problem.id}, type: ${problem.type}, ` +
                    `name: ${problem.name}`,
            ),
        };
    };
}

export function submitFeedback(questionId, answers) {
    return async function (dispatch, getState) {
        const cid = getState().data.consultations.consultation.id;
        const url = `${ACCOUNTS_FEEDBACK_API}${questionId}/`;
        await http.post({
            url,
            data: {
                answers,
                consultation: cid,
            },
        });
        dispatch({
            type: actions.data.SUBMIT_FEEDBACK_SUCCESS,
            payload: {
                answers,
                qid: questionId,
            },
        });
    };
}

export function undoFeedback(questionId) {
    return async function (dispatch) {
        const url = `${ACCOUNTS_FEEDBACK_API}${questionId}/`;
        await http.delete({url});
        dispatch({
            type: actions.data.UNDO_SUBMIT_FEEDBACK_SUCCESS,
            payload: {
                qid: questionId,
            },
        });
    };
}

export function submitMedicationCourse(
    {
        stepId,
        medicationCourse,
        medicationSearchTermId,
        isPrescriptionReasonForVisit = false,
        displayText,
    },
    reload = false,
) {
    return async function (dispatch, getState) {
        const {id} = getState().data.consultations.consultation;
        medicationSearchTermId = medicationSearchTermId || null;
        const data = {medicationSearchTerm: medicationSearchTermId};
        // eslint-disable-next-line camelcase
        data.is_prescription_reason_for_visit = isPrescriptionReasonForVisit;
        let response;
        if (reload) {
            response = medicationCourse;
        } else {
            response = snakeToCamelObjectKeys(
                await http.post({
                    // eslint-disable-next-line max-len
                    url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/medication-courses/`,
                    data,
                }),
            );
        }

        dispatch(
            batchActions(
                {
                    type: actions.data.ADD_MEDICATION_COURSE,
                    payload: response,
                },
                // TODO: Remove cmcId, and use problem.medicationCourseId instead
                {
                    type: actions.form.SET_STEP_ATTR,
                    payload: {
                        stepId,
                        field: 'component.props.cmcId',
                        value: response.id,
                    },
                },
                {
                    type: actions.data.SET_CONSULTATION_ATTR,
                    payload: {
                        field: 'medicationCourses',
                        value: [response.id],
                        merge: true,
                    },
                },
            ),
        );
        if (response.medicationSearchTerm) {
            dispatch({
                type: actions.data.ADD_CLINICAL_MEDICATION,
                payload: response.medicationSearchTerm,
            });
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.medicationSearchTermId',
                    value: response.medicationSearchTerm.id,
                },
            });
        }
        if (isPrescriptionReasonForVisit) {
            const {medicationSearchTerm, id} = response;
            const problem = {
                displayText: displayText || medicationSearchTerm?.name,
                medicationSearchTermId,
                medicationCourseId: id,
                type: PROBLEM_TYPE_PRESCRIPTION,
            };
            dispatch(
                addStep({
                    type: 'RepeatPrescriptionStep',
                    props: {problem},
                    parentId: stepId,
                }),
            );
            if (!reload) {
                dispatch({
                    type: actions.form.SET_STEP_ATTR,
                    payload: {
                        stepId,
                        field: 'component.props.problem',
                        value: problem,
                    },
                });
            }
            // TODO: add steps for prescription rfv
        } else {
            const {medicationSearchTerm, id} = response;
            dispatch(
                addStepsForMedicationStep({
                    stepId,
                    medicationSearchTerm,
                    medicationCourseId: id,
                }),
            );
        }
    };
}

export function undoMedicationCourse({stepId}) {
    return async function (dispatch, getState) {
        const {
            id,
            medicationCourses,
        } = getState().data.consultations.consultation;
        const step = getState().form.steps.find((step) => step.id === stepId);
        const {cmcId, medicationSearchTermId} = step.component.props;
        await http.delete({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/medication-courses/${cmcId}/`,
        });

        const undoActions = [
            {
                type: actions.data.REMOVE_MEDICATION_COURSE,
                payload: {id: cmcId},
            },
            {
                type: actions.data.SET_CONSULTATION_ATTR,
                payload: {
                    field: 'medicationCourses',
                    value: medicationCourses.filter((item) => item !== cmcId),
                },
            },
            {
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: undefined,
                },
            },
            {
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.cmcId',
                    value: undefined,
                },
            },
        ];
        if (medicationSearchTermId) {
            undoActions.push({
                type: actions.data.REMOVE_CLINICAL_MEDICATION,
                payload: {id: medicationSearchTermId},
            });
        }
        dispatch(batchActions(...undoActions));
    };
}
