import React from 'react';
import {Link} from 'react-router-dom';

import preserveQueryString from 'utils/preserve_query_string.js';

export default class LinkWithPreservedQueryString extends React.Component {
    render() {
        const {to, preservedQueryKeys, ...props} = this.props;

        return (
            <Link
                {...props}
                to={preserveQueryString(to, preservedQueryKeys)}
            />
        );
    }
}
