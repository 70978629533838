import autobind from 'common/decorators/autobind.js';
import {connect} from 'react-redux';
import {defineMessages, injectIntl} from 'react-intl';
import React from 'react';

import {updatePracticeUserDashboard} from 'accounts/actions/accountsActions.js';
import {showNotification} from 'better_consult/actions/appActions.js';
import SingleInput from 'core/components/SingleInput.js';
import {setData} from 'dashboard/actions/dashboardActions.js';

const messages = defineMessages({
    currentPasswordLabel: {
        id: 'dashboard.PasswordSettings.currentPasswordLabel',
        defaultMessage: 'currentPasswordLabel',
    },
    currentPasswordPlaceholder: {
        id: 'dashboard.PasswordSettings.currentPasswordPlaceholder',
        defaultMessage: 'currentPasswordPlaceholder',
    },
    newPasswordLabel: {
        id: 'dashboard.PasswordSettings.newPasswordLabel',
        defaultMessage: 'newPasswordLabel',
    },
    newPasswordPlaceholder: {
        id: 'dashboard.PasswordSettings.newPasswordPlaceholder',
        defaultMessage: 'newPasswordPlaceholder',
    },
    confirmPasswordLabel: {
        id: 'dashboard.PasswordSettings.confirmPasswordLabel',
        defaultMessage: 'confirmPasswordLabel',
    },
    confirmationPasswordErrorMessage: {
        id: 'dashboard.PasswordSettings.confirmationPasswordErrorMessage',
        defaultMessage: 'confirmationPasswordErrorMessage',
    },
    confirmationPasswordPlaceholder: {
        id: 'dashboard.PasswordSettings.confirmationPasswordPlaceholder',
        defaultMessage: 'confirmationPasswordPlaceholder',
    },
    passwordSettingsHeader: {
        id: 'dashboard.PasswordSettings.passwordSettingsHeader',
        defaultMessage: 'passwordSettingsHeader',
    },
    submitButtonText: {
        id: 'dashboard.PasswordSettings.submitButtonText',
        defaultMessage: 'submitButtonText',
    },
    generalErrorMessage: {
        id: 'dashboard.PasswordSettings.generalErrorMessage',
        defaultMessage: 'generalErrorMessage',
    },
    successMessage: {
        id: 'dashboard.PasswordSettings.successMessage',
        defaultMessage: 'successMessage',
    },
});

export class PasswordSettings extends React.Component {
    constructor(props) {
        super(props);

        this.passwordSettings = {
            newPassword: '',
            password: '',
        };

        this.state = {
            confirmNewPassword: '',
            errors: {
                confirmNewPassword: null,
                newPassword: null,
                password: null,
            },
            loading: false,
            success: false,
        };

        this.updatePassword = this.updateField.bind(this, 'password');
        this.updateNewPassword = this.updateField.bind(this, 'newPassword');
    }

    componentDidMount() {
        this.props.setData({
            showPractices: false,
            showPractitioners: false,
            showConsultations: false,
            showSearch: false,
        });
    }
    @autobind
    confirmNewPassword(value) {
        if (this.state.errors.confirmNewPassword || !value) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    confirmNewPassword: null,
                },
            });
        }
        this.setState({confirmNewPassword: value});
    }

    confirmPasswordErrState() {
        return {
            errors: {
                ...this.state.errors,
                confirmNewPassword: [
                    this.props.intl.formatMessage(
                        messages.confirmationPasswordErrorMessage,
                    ),
                ],
            },
        };
    }

    @autobind
    handleBlur() {
        if (
            this.state.confirmNewPassword &&
            this.passwordSettings.newPassword !== this.state.confirmNewPassword
        ) {
            this.setState(this.confirmPasswordErrState());
        }
    }

    updateField(name, value) {
        const {success, errors} = this.state;
        if (success) {
            this.setState({success: false});
        } else if (errors) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    confirmNewPassword: null,
                    [name]: null,
                },
            });
        }
        this.passwordSettings = {
            ...this.passwordSettings,
            [name]: value,
        };
    }

    showNotification(response) {
        const intl = this.props.intl;
        let notification;
        if (response && response.error) {
            notification = {
                title: intl.formatMessage(messages.generalErrorMessage),
                isTimedNotice: false,
                type: 'error',
            };
        } else {
            notification = {
                title: intl.formatMessage(messages.successMessage),
                isTimedNotice: true,
                timedNoticeDelay: 5000,
                type: 'success',
            };
        }
        this.props.showNotification(notification);
    }

    @autobind
    async updatePasswordSettings(event) {
        event.preventDefault();
        if (
            this.state.errors.confirmNewPassword ||
            this.passwordSettings.newPassword !== this.state.confirmNewPassword
        ) {
            return this.setState(this.confirmPasswordErrState());
        }
        this.setState({loading: true});
        const response = await this.props.updatePracticeUserDashboard(
            this.props.userId,
            this.passwordSettings,
        );
        let errors;
        let success = true;
        if (response.error) {
            errors = response.errors;
            success = false;
            if (!Object.keys(errors).length) {
                this.showNotification(response);
            }
            this.setState({errors, loading: false, success});
        } else {
            this.passwordSettings.password = '';
            this.passwordSettings.newPassword = '';
            this.setState({confirmNewPassword: ''});
            this.showNotification();
            this.setState({loading: false, success});
        }
    }

    render() {
        const intl = this.props.intl;
        const {errors} = this.state;
        return (
            <section className="workspace">
                <form
                    aria-busy={this.state.loading}
                    onSubmit={this.updatePasswordSettings}
                >
                    <h1>
                        {intl.formatMessage(messages.passwordSettingsHeader)}
                    </h1>
                    <SingleInput
                        clearField={this.state.success}
                        disabled={this.state.loading}
                        errors={errors.password}
                        id="password"
                        label={intl.formatMessage(
                            messages.currentPasswordLabel,
                        )}
                        name="password"
                        onChange={this.updatePassword}
                        placeholder={intl.formatMessage(
                            messages.currentPasswordPlaceholder,
                        )}
                        required={true}
                        reveal={true}
                        type="password"
                    />
                    <SingleInput
                        clearField={this.state.success}
                        disabled={this.state.loading}
                        errors={errors.newPassword}
                        id="password-new"
                        label={intl.formatMessage(messages.newPasswordLabel)}
                        name="password-new"
                        onChange={this.updateNewPassword}
                        placeholder={intl.formatMessage(
                            messages.newPasswordPlaceholder,
                        )}
                        required={true}
                        reveal={true}
                        type="password"
                    />
                    <SingleInput
                        clearField={this.state.success}
                        disabled={this.state.loading}
                        errors={this.state.errors.confirmNewPassword}
                        id="password-confirm"
                        label={intl.formatMessage(
                            messages.confirmPasswordLabel,
                        )}
                        name="password-confirm"
                        onBlur={this.handleBlur}
                        onChange={this.confirmNewPassword}
                        placeholder={intl.formatMessage(
                            messages.confirmationPasswordPlaceholder,
                        )}
                        required={true}
                        reveal={true}
                        type="password"
                    />
                    <button
                        className="cta"
                        disabled={
                            this.state.loading ||
                            Object.values(errors).some((e) => e !== null)
                        }
                        type="submit"
                    >
                        {intl.formatMessage(messages.submitButtonText)}
                    </button>
                </form>
            </section>
        );
    }
}

export function mapStateToProps(store) {
    return {
        userId: store.accounts.user.id,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updatePracticeUserDashboard: (uid, data) =>
            dispatch(updatePracticeUserDashboard(uid, data)),
        setData: (data) => dispatch(setData(data)),
        showNotification: (notification) =>
            dispatch(showNotification(notification)),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(PasswordSettings),
);
