import {ZONE} from 'accounts/helpers.js';
import actions from 'better_consult/actions/types.js';
import batchActions from 'better_consult/actions/batchActions.js';
import {selectPatient} from 'accounts/actions/accountsActions.js';
import {
    DEMOGRAPHICS_HABIT_THRESHOLD,
    EMPLOYMENT_STATUS_THRESHOLD,
    getAnswersLinkedToBodyParts,
    getClinicalCondition,
    getConstitutionalSymptoms,
    sortRelatedSymptomsByBodySystems,
    getClinicalSymptom,
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {saveStore} from 'consultations/actions/consultationActions.js';
import {consultationFeatureFlags, solutions} from 'consultations/helpers.js';
import {types} from 'questions/helpers.js';
import deduplicate from 'utils/deduplicate.js';

export function addSteps({steps, parentId, insertAfter, insertAfterFunc}) {
    return function (dispatch, getState) {
        const store = getState();
        let newIndex;
        if (insertAfter) {
            newIndex = store.form.steps.findIndex(
                (step) => step.component.type === insertAfter,
            );
        } else if (insertAfterFunc) {
            newIndex = insertAfterFunc(store);
        }
        dispatch({
            type: actions.form.ADD_STEPS,
            payload: {
                steps,
                parentId,
                newIndex,
            },
        });
        if (steps.length && !store.form.reloading) {
            dispatch(
                updateProgress({
                    update: actions.form.PUSH_INCREMENT,
                    parentId,
                }),
            );
        }
    };
}

export function addStep({
    type,
    props,
    parentId,
    insertAfter,
    insertAfterFunc,
    undoable,
}) {
    return function (dispatch, getState) {
        const store = getState();
        let newIndex;
        if (insertAfter) {
            newIndex = store.form.steps.findIndex(
                (step) => step.component.type === insertAfter,
            );
        } else if (insertAfterFunc) {
            newIndex = insertAfterFunc(store);
        }
        dispatch({
            type: actions.form.ADD_STEPS,
            payload: {
                steps: [
                    {
                        type,
                        props,
                        undoable,
                    },
                ],
                parentId,
                newIndex,
            },
        });
        if (!store.form.reloading) {
            dispatch(
                updateProgress({
                    update: actions.form.PUSH_INCREMENT,
                    parentId,
                }),
            );
        }
    };
}

export function removeChildrenSteps(stepId) {
    return {
        type: actions.form.REMOVE_CHILDREN_STEPS,
        payload: {stepId},
    };
}

export function removeStep(stepId) {
    return {
        type: actions.form.REMOVE_STEP,
        payload: {
            stepId,
        },
    };
}

export function setStepLoading(stepId, isLoading) {
    return {
        type: actions.form.SET_STEP_ATTR,
        payload: {
            stepId,
            field: 'loading',
            value: isLoading,
        },
    };
}

export function setStepReloaded(stepId, isReloaded) {
    return function (dispatch, getState) {
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'reloaded',
                value: isReloaded,
            },
        });
        const {steps, reloading} = getState().form;
        if (reloading && steps.every((step) => step.reloaded)) {
            dispatch({
                type: actions.form.SET_FORM_RELOADING,
                payload: {
                    reloading: false,
                },
            });
            dispatch(updateProgress({update: actions.form.RELOAD_INCREMENTS}));
            dispatch(saveStore());
        }
    };
}

export function completeStep(stepId) {
    return function (dispatch, getState) {
        const {form} = getState();
        const steps = form.steps;
        const stepIndex = steps.findIndex((step) => step.id === stepId);
        const step = steps[stepIndex];
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.completed',
                value: true,
            },
        });
        if (!form.reloading) {
            if (step.undoable) {
                dispatch(
                    updateProgress({
                        update: actions.form.PUSH_ADDEND,
                        stepId,
                    }),
                );
            } else {
                dispatch(updateProgress({update: actions.form.FINAL_STEP}));
            }
        }
        // loading to remain true on SubmitConsultationStep
        if (stepIndex !== steps.length - 1) {
            dispatch(setStepLoading(stepId, false));
        }
    };
}

export function undoStep(stepId) {
    return function (dispatch, getState) {
        const steps = getState().form.steps;
        const stepIndex = steps.findIndex((step) => step.id === stepId);
        const step = steps[stepIndex];
        dispatch(
            updateProgress({
                update: actions.form.POP_ADDEND,
                undoable: step.undoable,
            }),
        );
        dispatch(undoIntermediateSteps(stepId));
        dispatch(
            batchActions(
                removeChildrenSteps(stepId),
                {
                    type: actions.form.SET_STEP_ATTR,
                    payload: {
                        stepId,
                        field: 'component.completed',
                        value: false,
                    },
                },
                {
                    type: actions.form.SET_STEP_ATTR,
                    payload: {
                        stepId,
                        field: 'undo',
                        value: false,
                    },
                },
                setStepLoading(stepId, false),
            ),
        );
        dispatch(
            updateProgress({
                update: actions.form.POP_INCREMENT,
                parentId: stepId,
            }),
        );
    };
}

function undoIntermediateSteps(parentStepId) {
    return function (dispatch, getState) {
        const {steps} = getState().form;
        const parentIndex = steps.findIndex(
            (step) => step.id === parentStepId,
        );
        const currentIndex = steps.findIndex(
            (step) => !step.component.completed,
        );
        for (let i = parentIndex + 1; i < currentIndex; i++) {
            dispatch(setStepToUndo(steps[i].id));
        }
    };
}

export function setStepToUndo(stepId) {
    return {
        type: actions.form.SET_STEP_ATTR,
        payload: {
            stepId,
            field: 'undo',
            value: true,
        },
    };
}

export function addInitialDefaultSteps() {
    return function (dispatch, getState) {
        const store = getState();
        const {consultation} = store.data.consultations;
        const {solution} = consultation;
        const {featureFlags} = store.app;
        const languageSelectFeatureFlagUS = featureFlags.find(
            (ff) => ff.name === consultationFeatureFlags.LANGUAGES_SELECT_US,
        );
        const askWalkInIfExisting = featureFlags.find(
            (ff) =>
                ff.name === consultationFeatureFlags.ASK_WALKIN_IF_EXISTING,
        );
        dispatch(
            addStep({
                type: 'BeginConsultationStep',
                undoable: false,
                props: {
                    enableLanguageSelect: languageSelectFeatureFlagUS.enabled,
                },
            }),
        );
        if (
            (askWalkInIfExisting.enabled && solution === solutions.WALK_IN) ||
            solution === solutions.NON_INTEGRATED_INVITATION
        ) {
            return dispatch(
                addStep({
                    type: 'WalkInPatientFlowStep',
                }),
            );
        }
        dispatch(addDefaultSteps());
    };
}

export function addDefaultSteps(parentStepId, reload = false) {
    return function (dispatch, getState) {
        const store = getState();
        const {user} = store.accounts;
        const {consultation} = store.data.consultations;
        const {isExistingPatient} = consultation;
        const {featureFlags, mustComplyWithHipaa} = store.app;
        const steps = [];
        const parentId = parentStepId ? parentStepId : null;
        // TODO: remove default patientId answer from consultation store
        // below once the initial data load populates the patient store.
        const patientId =
            store.data.accounts.patient.id || consultation.patientId;
        const previousPatientSelectionStep = store.form.previousSteps.find(
            (step) => step.component.type === 'PatientSelectionStep',
        );
        if (previousPatientSelectionStep) {
            const {
                newPatientId,
            } = previousPatientSelectionStep.component.props;
            steps.push({
                type: 'PatientSelectionStep',
                props: {
                    mustComplyWithHipaa,
                    newPatientId,
                },
            });
        } else if (user.patients.items.length > 1) {
            steps.push({
                type: 'PatientSelectionStep',
                props: {
                    mustComplyWithHipaa,
                    newPatientId: patientId,
                },
            });
        } else {
            const patient = user.patients.items[0];
            dispatch(selectPatient(patientId));
            steps.push({
                type: 'PatientNameStep',
                props: {
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    mustComplyWithHipaa,
                },
            });
            if (isExistingPatient) {
                const {previousSteps} = store.form;
                if (
                    !patient.sex ||
                    (reload &&
                        previousSteps.find(
                            (step) =>
                                step.component.type === 'DemographicsSexStep',
                        ))
                ) {
                    steps.push({type: 'DemographicsSexStep'});
                }
                if (
                    !patient.birthDate ||
                    (reload &&
                        previousSteps.find(
                            // eslint-disable-next-line max-len
                            (step) =>
                                step.component.type ===
                                'DemographicsDateOfBirthStep',
                        ))
                ) {
                    if (mustComplyWithHipaa) {
                        steps.push({type: 'DemographicsAgeStep'});
                    } else {
                        steps.push({type: 'DemographicsDateOfBirthStep'});
                    }
                }
            }
        }
        if (!isExistingPatient) {
            steps.push({type: 'DemographicsSexStep'});
            if (mustComplyWithHipaa) {
                steps.push({type: 'DemographicsAgeStep'});
            } else {
                steps.push({type: 'DemographicsDateOfBirthStep'});
            }
        }
        steps.push({type: 'ProblemSearchStep'});
        if (!isExistingPatient) {
            const addMedicationStep = featureFlags.find(
                // eslint-disable-next-line max-len
                (ff) =>
                    ff.name === consultationFeatureFlags.ADD_MEDICATION_STEP,
            ).enabled;
            if (addMedicationStep) {
                steps.push({
                    type: 'RepeatStep',
                    props: {
                        titleKey: 'medicationStepTitle',
                        step: {
                            type: 'MedicationStep',
                        },
                    },
                });
            }
            steps.push({
                type: 'ProblemPastConditionsCheckpointStep',
                undoable: false,
            });
            steps.push({
                type: 'FamilyConditionsStep',
            });
        }

        steps.push({
            type: 'GlobalQuestionCheckpointStep',
            undoable: false,
        });

        const reviewOfSystemsFeatureFlag = featureFlags.find(
            (ff) => ff.name === consultationFeatureFlags.US_REVIEWS_OF_SYSTEMS,
        )?.enabled;
        if (reviewOfSystemsFeatureFlag && mustComplyWithHipaa) {
            steps.push({
                type: 'ReviewOfSystemsCheckpointStep',
                undoable: false,
            });
        }

        steps.push(
            {
                type: 'FeedbackQuestionCheckpointStep',
                undoable: false,
            },
            {
                type: 'SubmitConsultationStep',
                props: {consultation},
                undoable: false,
            },
        );
        dispatch(addSteps({parentId, steps}));
    };
}

export function constitutionalSymptomsAsked(store) {
    const formSteps = store.form.steps;
    return formSteps.some(
        (step) =>
            step.component.props.constitutional &&
            step.component.type === 'RelatedSymptomsStep',
    );
}

// returns constitutional step with unselected symptoms
export function createConstitutionalSteps(store, problem) {
    const {consultationSymptoms} = store.data.consultations;
    let constitutionalSymptoms = Object.values(
        store.data.clinical.constitutionalSymptoms,
    );
    const presentingSymptomsIdList = Object.values(consultationSymptoms).map(
        (symptom) => symptom.symptomId,
    );
    constitutionalSymptoms = constitutionalSymptoms.filter(
        (symptom) => !presentingSymptomsIdList.includes(symptom.id),
    );
    constitutionalSymptoms = constitutionalSymptoms.map((cs) => ({
        text: cs.name,
        id: cs.id,
    }));

    const steps = [];
    if (constitutionalSymptoms.length) {
        steps.push({
            type: 'RelatedSymptomsStep',
            props: {
                questionType: types.CHECKBOX,
                answers: constitutionalSymptoms,
                constitutional: true,
                problem,
            },
        });
    }
    return steps;
}

export function generateRelatedSymptomsStepForCondition(
    store,
    problem,
    selectedSymptomIds,
) {
    let relatedSymptoms =
        getClinicalCondition(store, problem.conditionId).relatedSymptoms || [];
    if (!selectedSymptomIds) {
        selectedSymptomIds = Object.values(
            store.data.consultations.consultationSymptoms,
        ).map((cs) => cs.symptomId);
        relatedSymptoms = relatedSymptoms.filter(
            (rs) => !selectedSymptomIds.includes(rs.id),
        );
    }
    const orderedRelatedSymptoms = sortRelatedSymptomsByBodySystems(
        relatedSymptoms,
        false,
    );
    const steps = [];
    if (orderedRelatedSymptoms.length) {
        steps.push({
            type: 'RelatedSymptomsStep',
            props: {
                answers: deduplicate(
                    orderedRelatedSymptoms,
                    (item) => item.id,
                ),
                consultationId: store.data.consultations.consultation.id,
                hasNoAnswerOption: true,
                questionType: problem.diagnosed ? types.CHECKBOX : types.RADIO,
                problem,
            },
        });
    }
    return steps;
}

export function stepsForConditionRelatedSymptom(store, problem) {
    const steps = [];
    const {symptomId} = problem;
    const symptom = store.data.clinical.symptoms[symptomId];

    if (symptom.characterGroups && symptom.characterGroups.length) {
        const multiple = symptom.characterGroups.length > 1;
        for (const characterGroup of symptom.characterGroups) {
            steps.push({
                type: 'CharacterStep',
                props: {
                    multiple,
                    questionType: characterGroup.isRadio
                        ? types.RADIO
                        : types.CHECKBOX,
                    problem,
                    answers: characterGroup.answers.map((answer) => ({
                        id: answer.id,
                        text: answer.name,
                    })),
                    hasNoAnswerOption: characterGroup.allowNoAnswer,
                    characterGroupText: characterGroup.name,
                },
            });
        }
    }
    if (symptom.severityQuestion) {
        const {severityQuestion} = symptom;
        steps.push({
            type: 'SeverityQuestionStep',
            props: {
                answers: severityQuestion.answers.map((answer) => ({
                    id: answer.id,
                    text: answer.name,
                })),
                hasNoAnswerOption: severityQuestion.allowNoAnswer,
                problem,
                title: severityQuestion.name,
            },
        });
    } else if (symptom.hasSeverity) {
        steps.push({
            type: 'SeverityStep',
            props: {
                problem,
            },
        });
    }

    return steps;
}

function getProblemQuestionSteps(symptom, problem) {
    const problemQuestionSteps = [];
    for (const question of symptom.questions) {
        problemQuestionSteps.push({
            type: 'ProblemQuestionStep',
            props: {
                answers: question.answers.map((answer) => ({
                    id: answer.id,
                    text: answer.name,
                })),
                hasNoAnswerOption: question.allowNoAnswer,
                questionType: question.type,
                problem,
                title: question.name,
                qid: question.id,
            },
        });
    }

    return problemQuestionSteps;
}

export function shouldAddRelatedSymptomSteps(featureFlags) {
    const showRelatedSymptoms = featureFlags.find(
        (flag) => flag.name === 'SHOW_RELATED_SYMPTOMS',
    )?.enabled;
    const redFlagRelatedSymptoms = featureFlags.find(
        (flag) => flag.name === 'RED_FLAG_RELATED_SYMPTOMS',
    )?.enabled;
    return !!showRelatedSymptoms || !!redFlagRelatedSymptoms;
}

export function addStepForRelatedSymptomsScreen(steps) {
    const step = {
        type: 'RelatedSymptomsScreenStep',
        undoable: false,
    };
    const index = steps.findIndex(
        (step) => step.type === 'RelatedSymptomsStepV2',
    );
    if (index !== -1) {
        steps.splice(index, 0, step);
    }
    return steps;
}

/* eslint-disable complexity */
export function addStepsForSymptom(
    {stepId, problem, isNewProblem},
    reload = false,
) {
    return function (dispatch, getState) {
        const store = getState();
        const {isExistingPatient} = store.data.consultations.consultation;
        const {featureFlags} = store.app;
        const {symptomId} = problem;
        const symptom = store.data.clinical.symptoms[symptomId];
        let steps = [];
        if (isExistingPatient && isNewProblem === undefined) {
            /* don't ask "Have you seen a doctor at this practice..."
               if the RFV is "follow-up" as of course they have */
            const {zone} = document.body.dataset;
            let followUpRfvId = null;
            if (zone === ZONE.AU) {
                followUpRfvId = 1337;
            } else if (zone === ZONE.US) {
                followUpRfvId = 549;
            }

            if (symptomId === followUpRfvId) {
                // still ask "What is the reason for your follow up"
                steps = steps.concat(
                    getProblemQuestionSteps(symptom, problem),
                );
            } else {
                dispatch(
                    addStep({
                        type: 'ProblemSeenBeforeStep',
                        props: {
                            problem,
                        },
                        parentId: stepId,
                    }),
                );
            }
        } else {
            if (symptom.hasDuration) {
                steps.push({
                    type: 'DurationStep',
                    props: {problem},
                });
            }
            if (symptom.hasPattern) {
                steps.push({
                    type: 'PatternStep',
                    props: {
                        problem,
                        isNewProblem,
                    },
                });
            }
            if (symptom.characterGroups && symptom.characterGroups.length) {
                const multiple = symptom.characterGroups.length > 1;
                for (const characterGroup of symptom.characterGroups) {
                    steps.push({
                        type: 'CharacterStep',
                        props: {
                            multiple,
                            // eslint-disable-next-line max-len
                            questionType: characterGroup.isRadio
                                ? types.RADIO
                                : types.CHECKBOX,
                            problem,
                            answers: characterGroup.answers.map((answer) => ({
                                id: answer.id,
                                text: answer.name,
                            })),
                            hasNoAnswerOption: characterGroup.allowNoAnswer,
                            characterGroupText: characterGroup.name,
                        },
                    });
                }
            }
            if (symptom.severityQuestion) {
                const {severityQuestion} = symptom;
                steps.push({
                    type: 'SeverityQuestionStep',
                    props: {
                        answers: severityQuestion.answers.map((answer) => ({
                            id: answer.id,
                            text: answer.name,
                        })),
                        hasNoAnswerOption: severityQuestion.allowNoAnswer,
                        problem,
                        title: severityQuestion.name,
                    },
                });
            } else if (symptom.hasSeverity) {
                steps.push({
                    type: 'SeverityStep',
                    props: {
                        problem,
                    },
                });
            }
            const bodyPartLateralisationFeature = featureFlags.find(
                // eslint-disable-next-line max-len
                (ff) =>
                    ff.name ===
                    consultationFeatureFlags.BODY_PART_LATERALISATION,
            );
            const enabled =
                bodyPartLateralisationFeature &&
                bodyPartLateralisationFeature.enabled;
            if (
                enabled &&
                symptom.hasLateralisation &&
                !symptom.hasRadiation
            ) {
                const hasMid = symptom.bodyParts.some(
                    (bp) => !bp.lateralisation,
                );
                steps.push({
                    type: 'BodyPartLateralisationStep',
                    props: {problem, hasMid},
                });
            }
            const impactsOnFunction = getAnswersLinkedToBodyParts(
                'impactsOnFunction',
                symptom,
            );
            if (symptom.hasImpactOnFunction && impactsOnFunction.length) {
                steps.push({
                    type: 'ImpactsOnFunctionStep',
                    props: {
                        answers: impactsOnFunction,
                        problem,
                    },
                });
            }
            const exacerbatingFactors = getAnswersLinkedToBodyParts(
                'exacerbatingFactors',
                symptom,
            );
            if (exacerbatingFactors.length) {
                steps.push({
                    type: 'ExacerbatingFactorsStep',
                    props: {
                        answers: exacerbatingFactors,
                        problem,
                    },
                });
            }
            const relievingFactors = getAnswersLinkedToBodyParts(
                'relievingFactors',
                symptom,
            );
            if (relievingFactors.length) {
                steps.push({
                    type: 'RelievingFactorsStep',
                    props: {
                        answers: relievingFactors,
                        problem,
                    },
                });
            }
            if (symptom.triggerEvents && symptom.triggerEvents.length) {
                steps.push({
                    type: 'TriggerEventsStep',
                    props: {
                        answers: symptom.triggerEvents.map((trigger) => ({
                            id: trigger.id,
                            text: trigger.name,
                        })),
                        problem,
                    },
                });
            }
            if (symptom.isNonMedical) {
                steps = steps.concat(
                    getProblemQuestionSteps(symptom, problem),
                );
            }
            const addRelatedSymptomSteps = shouldAddRelatedSymptomSteps(
                featureFlags,
            );
            const showRelatedSymptoms = !!featureFlags.find(
                (flag) => flag.name === 'SHOW_RELATED_SYMPTOMS',
            )?.enabled;
            if (addRelatedSymptomSteps) {
                let bodySystemRelatedSymptoms =
                    getClinicalSymptom(store, symptomId).relatedSymptoms || [];
                bodySystemRelatedSymptoms = sortRelatedSymptomsByBodySystems(
                    bodySystemRelatedSymptoms,
                );

                const constitutional = getConstitutionalSymptoms(
                    store,
                    symptomId,
                );

                if (constitutional && showRelatedSymptoms) {
                    bodySystemRelatedSymptoms.constitutional = constitutional;
                }
                let otherStep;
                let constitutionalStep;
                const {consultationSymptoms} = store.data.consultations;
                for (const [bs, rs] of Object.entries(
                    bodySystemRelatedSymptoms,
                )) {
                    // TODO: use createConstitutionalSteps for constitutional
                    const presentingSymptomsIdList = Object.values(
                        consultationSymptoms,
                    ).map((symptom) => symptom.symptomId);
                    const filtered = rs.filter(
                        (symptom) =>
                            !presentingSymptomsIdList.includes(symptom.id),
                    );

                    let answers = [];
                    if (reload) {
                        const currentStepType = store.form.steps.find(
                            (step) => step.id === stepId,
                        ).component.type;
                        const currentSteps = store.form.steps.filter(
                            (step) => step.component.type === currentStepType,
                        );
                        const currentStepIndex = currentSteps.findIndex(
                            (step) =>
                                step.id === stepId &&
                                step.component.type === currentStepType,
                        );
                        const previousSteps = store.form.previousSteps.filter(
                            (step) => step.component.type === currentStepType,
                        );
                        const previousStep = previousSteps[currentStepIndex];
                        const relatedSymptomsStepChild = store.form.previousSteps.find(
                            (step) => {
                                const stepType = step.component.props
                                    .constitutional
                                    ? 'RelatedSymptomsStep'
                                    : 'RelatedSymptomsStepV2';
                                return (
                                    step.parent === previousStep.id &&
                                    step.component.props.bodySystem === bs &&
                                    step.component.type === stepType
                                );
                            },
                        );
                        if (relatedSymptomsStepChild) {
                            answers =
                                relatedSymptomsStepChild.component.props
                                    .answers;
                        }
                    }

                    if (!answers.length && filtered.length) {
                        answers = deduplicate(filtered, (item) => item.id);
                    }

                    if (answers.length) {
                        const step = {
                            type: 'RelatedSymptomsStepV2',
                            props: {
                                answers,
                                // eslint-disable-next-line max-len
                                consultationId:
                                    store.data.consultations.consultation.id,
                                problem,
                                bodySystem: bs,
                                other: bs === 'other',
                                constitutional: bs === 'constitutional',
                                questionType: types.CHECKBOX,
                            },
                        };
                        if (bs === 'other') {
                            otherStep = step;
                        } else if (bs === 'constitutional') {
                            step.type = 'RelatedSymptomsStep';
                            constitutionalStep = step;
                        } else {
                            steps.push(step);
                        }
                    }
                }
                if (otherStep) {
                    steps.push(otherStep);
                }
                if (
                    !constitutionalSymptomsAsked(store) &&
                    constitutionalStep
                ) {
                    steps.push(constitutionalStep);
                }
            }
            if (!symptom.isNonMedical) {
                steps = steps.concat(
                    getProblemQuestionSteps(symptom, problem),
                );
                if (showRelatedSymptoms) {
                    steps = addStepForRelatedSymptomsScreen(steps);
                }
            }
        }
        if (steps.length) {
            dispatch(
                addSteps({
                    steps,
                    parentId: stepId,
                }),
            );
        }
    };
}

/* eslint-enable complexity */
export function addStepsForCondition({
    stepId,
    problem,
    isNewProblem,
    selectedSymptomIds,
}) {
    return function (dispatch, getState) {
        const store = getState();
        const {isExistingPatient} = store.data.consultations.consultation;
        const {conditionId} = problem;
        const condition = store.data.clinical.conditions[conditionId];
        if (isExistingPatient && isNewProblem === undefined) {
            dispatch(
                addStep({
                    type: 'ProblemSeenBeforeStep',
                    props: {problem},
                    parentId: stepId,
                }),
            );
        } else {
            const steps = [
                {
                    type: 'PastConditionWhenDiagnosedStep',
                    props: {problem},
                    parentId: stepId,
                },
                ...generateRelatedSymptomsStepForCondition(
                    store,
                    {
                        ...problem,
                        diagnosed: true,
                    },
                    selectedSymptomIds,
                ),
            ];
            if (condition.questions && condition.questions.length) {
                // eslint-disable-next-line max-len
                for (const {
                    question,
                    isReasonForVisit,
                } of condition.questions) {
                    if (isReasonForVisit) {
                        steps.push({
                            type: 'ProblemQuestionStep',
                            props: {
                                answers: question.answers.map((answer) => ({
                                    id: answer.id,
                                    text: answer.name,
                                })),
                                hasNoAnswerOption: question.allowNoAnswer,
                                questionType: question.type,
                                problem,
                                title: question.name,
                                qid: question.id,
                            },
                        });
                    }
                }
            }
            dispatch(
                addSteps({
                    steps,
                    parentId: stepId,
                }),
            );
        }
    };
}

export function addStepsForUndiagnosedCondition({
    stepId,
    problem,
    selectedSymptomIds,
}) {
    return function (dispatch, getState) {
        const store = getState();

        const steps = generateRelatedSymptomsStepForCondition(
            store,
            {
                ...problem,
                diagnosed: false,
            },
            selectedSymptomIds,
        );

        dispatch(addSteps({steps, parentId: stepId}));
    };
}

export function addStepsForFreeTextProblem({stepId, problem, isNewProblem}) {
    return function (dispatch, getState) {
        const store = getState();
        const {isExistingPatient} = store.data.consultations.consultation;
        if (isExistingPatient && isNewProblem === undefined) {
            dispatch(
                addStep({
                    type: 'ProblemSeenBeforeStep',
                    props: {problem},
                    parentId: stepId,
                }),
            );
        }
    };
}

function getIndicatingConditionsAndSymptoms(medication) {
    return [
        ...medication.indicatingConditions.map((item) => ({
            type: PROBLEM_TYPE_CONDITION,
            id: item.id,
            text: item.name,
        })),
        ...medication.indicatingSymptoms.map((item) => ({
            type: PROBLEM_TYPE_SYMPTOM,
            id: item.id,
            text: item.name,
        })),
    ];
}

export function addStepsForNewPrescription({stepId, problem}) {
    return function (dispatch, getState) {
        const store = getState();
        const {medicationId, medicationCourseId} = problem;
        let answers = [];
        const {featureFlags} = store.app;
        const transitionToOtherFlow = featureFlags.find(
            (ff) =>
                ff.name ===
                consultationFeatureFlags.TRANSITION_FROM_PRESCRIPTION_TO_SYMPTOM_OR_CONDITION_FLOW,
        )?.enabled;
        const medication = store.data.clinical.medications[medicationId];
        if (medication) {
            answers = getIndicatingConditionsAndSymptoms(medication);
        }
        dispatch(
            addStep({
                type: 'MedicationForProblemsStep',
                props: {
                    answers,
                    hasNoAnswerOption: true,
                    isPrescriptionReasonForVisit: true,
                    isRepeatPrescription: false,
                    medicationCourseId,
                    questionType: types.RADIO,
                    transitionToOtherFlow,
                },
                parentId: stepId,
            }),
        );
    };
}

export function addStepsForRepeatPrescription({stepId, problem}) {
    return function (dispatch, getState) {
        const store = getState();
        const {medicationId, medicationCourseId} = problem;
        let answers = [];
        let steps = [];
        const medication = store.data.clinical.medications[medicationId];
        if (medication) {
            answers = getIndicatingConditionsAndSymptoms(medication);
        }
        steps.push(
            {
                type: 'MedicationForProblemsStep',
                props: {
                    answers,
                    isPrescriptionReasonForVisit: true,
                    isRepeatPrescription: true,
                    medicationCourseId,
                },
            },
            {
                type: 'MedicationComplianceStep',
                props: {
                    medicationCourseId,
                    hasNoAnswerOption: false,
                },
            },
        );

        // skip problem question steps if medication is null
        if (medicationId) {
            steps = steps.concat(getProblemQuestionSteps(medication, problem));
        }
        dispatch(
            addSteps({
                steps,
                parentId: stepId,
            }),
        );
    };
}

// This function is here to address the issue that happens when
// we reload 'FamilyConditionsStep'. As the form is rendered in
// reverse, the 'FamilyConditionsStep' is reloaded before the
// earlier steps and as a result, it impacts on the sequence of
// steps being added.
export function addFamilyConditionSteps(store) {
    const famConRelationshipSteps = store.form.steps.filter(
        (step) => step.component.type === 'FamilyConditionRelationshipStep',
    );
    if (famConRelationshipSteps.length) {
        const stepsToAdd = famConRelationshipSteps.length - 1;
        const index = store.form.steps.findIndex(
            (step) =>
                step.component.type === 'FamilyConditionRelationshipStep',
        );
        return index + stepsToAdd;
    }
    return store.form.steps.findIndex(
        (step) => step.component.type === 'FamilyConditionsStep',
    );
}

export function addStepsForAge({stepId, age}) {
    return function (dispatch) {
        const steps = [];
        if (age >= EMPLOYMENT_STATUS_THRESHOLD) {
            steps.push({type: 'DemographicsEmploymentStatusStep'});
        } else {
            steps.push({type: 'DemographicsCareEnvironmentStep'});
        }

        if (age >= DEMOGRAPHICS_HABIT_THRESHOLD) {
            steps.push(
                {type: 'DemographicsAlcoholFrequencyStep'},
                {type: 'DemographicsSmokingStatusStep'},
                {type: 'DemographicsRecreationalDrugStatusStep'},
                {type: 'DemographicsMaritalStatusStep'},
            );
        }
        dispatch(
            addSteps({
                steps,
                parentId: stepId,
                insertAfterFunc: addFamilyConditionSteps,
            }),
        );
    };
}

export function addStepsForMedicationStep({
    stepId,
    medicationSearchTerm,
    medicationCourseId,
}) {
    return function (dispatch) {
        dispatch(
            addSteps({
                parentId: stepId,
                steps: [
                    {
                        type: 'MedicationForProblemsStep',
                        props: {
                            medicationCourseId,
                            answers: [
                                ...medicationSearchTerm.indicatingConditions.map(
                                    (item) => ({
                                        type: PROBLEM_TYPE_CONDITION,
                                        id: item.id,
                                        text: item.name,
                                    }),
                                ),
                                ...medicationSearchTerm.indicatingSymptoms.map(
                                    (item) => ({
                                        type: PROBLEM_TYPE_SYMPTOM,
                                        id: item.id,
                                        text: item.name,
                                    }),
                                ),
                            ],
                        },
                    },
                    {
                        type: 'RepeatStep',
                        props: {
                            titleKey: 'medicationStepSecondaryTitle',
                            step: {
                                type: 'MedicationStep',
                                props: {
                                    secondary: true,
                                },
                            },
                        },
                    },
                ],
            }),
        );
    };
}

export function setDataOnStep({stepId, field, value}) {
    return function (dispatch) {
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: `component.props.${field}`,
                value,
            },
        });
    };
}

export function updateProgress({update, parentId, undoable}) {
    return function (dispatch) {
        dispatch({
            type: actions.form.UPDATE_PROGRESS,
            payload: {
                update,
                parentId,
                undoable,
            },
        });
    };
}
