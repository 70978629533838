import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import Icon from 'utils/components/Icon.js';

export const messages = defineMessages({
    buttonTitle: {
        id: 'core.ScrollToButton.buttonTitle',
        defaultMessage: 'buttonTitle',
    },
});

export class ScrollToButton extends React.Component {
    static propTypes = {
        scrollTo: PropTypes.func.isRequired,
    };

    @autobind
    handleClick() {
        const prefersReducedMotion = window.matchMedia(
            '(prefers-reduced-motion: reduce)',
        ).matches;
        const behaviour = prefersReducedMotion ? 'auto' : 'smooth';
        this.props.scrollTo(behaviour);
    }

    render() {
        return (
            <button
                className="scroll-control"
                data-test-id="scroll-to-button"
                onClick={this.handleClick}
                title={this.props.intl.formatMessage(messages.buttonTitle)}
            >
                <Icon name="IconChevron" />
            </button>
        );
    }
}

export default injectIntl(ScrollToButton);
