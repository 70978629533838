import moment from 'moment';
import {defineMessages} from 'react-intl';

import {
    SEX_FEMALE,
    SEX_MALE,
    SEX_OTHER,
    SEX_NO_ANSWER,
} from 'clinical/helpers.js';

export function findSelectedResult(results, currentUser) {
    const selected = results.find((result) => result.selected);
    if (selected) {
        return selected;
    } else {
        if (currentUser) {
            const result = results.find((res) => res.id === currentUser);
            if (result) {
                return result;
            }
        }
        return results[0];
    }
}

export function findPractitionersForPractice(practice, practitioners) {
    return practitioners.filter(({practices}) =>
        practices.includes(practice.id),
    );
}

export const messages = defineMessages({
    sexFemale: {
        id: 'dashboard.Helpers.sexFemale',
        defaultMessage: 'sexFemale',
    },
    sexFemaleFull: {
        id: 'dashboard.Helpers.sexFemaleFull',
        defaultMessage: 'sexFemaleFull',
    },
    sexMale: {
        id: 'dashboard.Helpers.sexMale',
        defaultMessage: 'sexMale',
    },
    sexMaleFull: {
        id: 'dashboard.Helpers.sexMaleFull',
        defaultMessage: 'sexMaleFull',
    },
    sexOther: {
        id: 'dashboard.Helpers.sexOther',
        defaultMessage: 'sexOther',
    },
    sexOtherFull: {
        id: 'dashboard.Helpers.sexOtherFull',
        defaultMessage: 'sexOtherFull',
    },
    sexNoAnswer: {
        id: 'dashboard.Helpers.sexNoAnswer',
        defaultMessage: 'sexNoAnswer',
    },
    sexNoAnswerFull: {
        id: 'dashboard.Helpers.sexNoAnswerFull',
        defaultMessage: 'sexNoAnswerFull',
    },
});

export function formatSex(sex, intl, full = false) {
    let message = null;

    if (full) {
        if (sex === SEX_FEMALE.id) {
            message = messages.sexFemaleFull;
        } else if (sex === SEX_MALE.id) {
            message = messages.sexMaleFull;
        } else if (sex === SEX_NO_ANSWER.id) {
            message = messages.sexNoAnswerFull;
        } else if (sex === SEX_OTHER.id) {
            message = messages.sexOtherFull;
        } else {
            console.warn('formatSex - invalid argument', {sex});
            message = messages.sexOtherFull;
        }

        return intl.formatMessage(message);
    } else {
        if (sex === SEX_FEMALE.id) {
            message = messages.sexFemale;
        } else if (sex === SEX_MALE.id) {
            message = messages.sexMale;
        }

        if (message) {
            return '(' + intl.formatMessage(message) + ')';
        } else {
            return '';
        }
    }
}

export function formatAge(birthDate) {
    let ageType = 'years';
    let age = moment().diff(birthDate, 'years');
    if (age < 1 && ageType === 'years') {
        age = moment().diff(birthDate, 'months');
        ageType = 'months';
    }
    if (age < 1 && ageType === 'months') {
        age = moment().diff(birthDate, 'days');
        ageType = 'days';
    }
    return {ageType, age};
}

export function getFormattedName(firstName, lastName, showInitials) {
    if (showInitials) {
        return `${firstName.charAt(0)} ${lastName.charAt(0)} `;
    } else {
        return `${firstName} ${lastName} `;
    }
}
