import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'utils/components/Icon.js';
import InputNotice from 'core/components/InputNotice.js';

export const messages = defineMessages({
    hidePassword: {
        id: 'core.SingleInput.hidePassword',
        defaultMessage: 'hidePassword',
    },
    showPassword: {
        id: 'core.SingleInput.showPassword',
        defaultMessage: 'showPassword',
    },
});

export class SingleInput extends React.Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        clearField: PropTypes.bool,
        disabled: PropTypes.bool,
        errors: PropTypes.array,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        labelLink: PropTypes.shape({
            to: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }),
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        reveal: PropTypes.bool,
        type: PropTypes.string.isRequired,
    };

    static defaultProps = {
        autoFocus: false,
        defaultValue: '',
        disabled: false,
        readOnly: false,
        required: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            showPassword: false,
            passwordFieldType: 'password',
            value: props.defaultValue,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.clearField && !prevProps.clearField) {
            this.setState({value: ''});
        }
        if (this.props.errors !== prevProps.errors) {
            if (this.props.errors) {
                this.setState({errors: this.props.errors});
            } else {
                this.setState({errors: []});
            }
        }
    }

    @autobind
    toggleShowPassword() {
        this.setState({
            showPassword: !this.state.showPassword,
            passwordFieldType: this.state.showPassword ? 'password' : 'text',
        });
    }

    @autobind
    handleInputChange(event) {
        this.setState({
            errors: [],
            value: event.currentTarget.value,
        });
        this.props.onChange(event.currentTarget.value, event);
    }

    renderError(error, index, errorId) {
        return <InputNotice errorId={errorId} key={index} message={error} />;
    }

    @autobind
    renderRevealButton() {
        const showButton = this.state.value && this.props.reveal;
        if (this.props.type === 'password') {
            return (
                <button
                    aria-hidden={!showButton}
                    aria-pressed={this.state.showPassword}
                    className="reveal"
                    data-test-id={
                        this.state.showPassword
                            ? 'hide-password-button'
                            : 'reveal-password-button'
                    }
                    key={`${this.props.id}-a`}
                    onClick={showButton ? this.toggleShowPassword : null}
                    title={
                        this.state.showPassword
                            ? this.props.intl.formatMessage(
                                  messages.hidePassword,
                              )
                            : this.props.intl.formatMessage(
                                  messages.showPassword,
                              )
                    }
                    type="button"
                >
                    {this.state.showPassword ? (
                        <Icon name="IconPasswordHide" />
                    ) : (
                        <Icon name="IconPasswordReveal" />
                    )}
                </button>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <label data-test-id={`${this.props.type}-label`}>
                    {this.props.label}
                    {this.props.labelLink && (
                        <Link to={this.props.labelLink.to}>
                            {this.props.labelLink.text}
                        </Link>
                    )}
                    <input
                        aria-describedby={`${this.props.id}-error`}
                        aria-invalid={this.state.errors.length > 0}
                        autoFocus={this.props.autoFocus}
                        data-test-id={`${this.props.type}-input`}
                        disabled={this.props.disabled}
                        key={`${this.props.id}-input`}
                        name={this.props.name}
                        onBlur={this.props.onBlur}
                        onChange={this.handleInputChange}
                        placeholder={this.props.placeholder}
                        readOnly={this.props.readOnly}
                        required={this.props.required}
                        type={
                            this.props.type === 'password'
                                ? this.state.passwordFieldType
                                : this.props.type
                        }
                        value={this.state.value}
                    />
                    {this.renderRevealButton()}
                    {this.state.errors.map((error, index) =>
                        this.renderError(
                            error,
                            index,
                            `${this.props.id}-error`,
                        ),
                    )}
                </label>
            </React.Fragment>
        );
    }
}

export default injectIntl(SingleInput);
