import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';

export default class TitledRoute extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    };

    componentDidMount() {
        document.title = `BetterConsult - ${this.props.title}`;
    }

    componentDidUpdate(prevProps) {
        if (this.props.title !== prevProps.title) {
            document.title = `BetterConsult - ${this.props.title}`;
        }
    }

    @autobind
    renderComponent(props) {
        return <this.props.component {...props} {...this.props} />;
    }

    render() {
        return <Route {...this.props.rest} render={this.renderComponent} />;
    }
}
