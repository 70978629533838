import autobind from 'common/decorators/autobind.js';
import events from 'events.js';
import React from 'react';
import PropTypes from 'prop-types';

export default class ProgressBar extends React.Component {
    static propTypes = {
        currentValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
    };

    static defaultProps = {
        maxValue: 100,
        minValue: 0,
    };

    constructor(props) {
        super(props);

        this.progressValueNode = null;
        this.state = {
            animateProgress: this.props.currentValue > 0,
            resetProgress: this.props.currentValue === 0,
        };
    }

    componentDidMount() {
        if (this.progressValueNode) {
            events.listen(
                this.progressValueNode,
                'webkitAnimationEnd',
                this.resetAnimation,
            );
        }
    }

    componentWillUnmount() {
        events.unlisten(
            this.progressValueNode,
            'webkitAnimationEnd',
            this.resetAnimation,
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentValue !== this.props.currentValue) {
            const isMinValue = this.props.currentValue === this.props.minValue;
            const previousIsMaxValue =
                prevProps.currentValue === this.props.maxValue;
            const resetProgress = isMinValue && previousIsMaxValue;
            const animateProgress = !resetProgress;
            this.setState({animateProgress, resetProgress});
        }
    }

    @autobind
    resetAnimation() {
        this.setState({animateProgress: false});
    }

    render() {
        let classList = '';
        if (this.state.animateProgress) {
            classList += 'animate';
        } else if (this.state.resetProgress) {
            classList += 'reset';
        }
        return (
            <div
                aria-valuemax={this.props.maxValue}
                aria-valuemin={this.props.minValue}
                aria-valuenow={this.props.currentValue}
                className="progress-bar"
                data-test-id="progress-bar"
                role="progressbar"
            >
                <span
                    {...(classList && {className: classList})}
                    ref={(node) => {
                        this.progressValueNode = node;
                    }}
                    style={{'--progress': `${this.props.currentValue}%`}}
                ></span>
            </div>
        );
    }
}
