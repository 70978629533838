import actions from 'better_consult/actions/types.js';

export function clearData() {
    return async function (dispatch) {
        dispatch({type: actions.dashboard.CLEAR_DATA});
    };
}

export function setData(data) {
    return async function (dispatch) {
        dispatch({
            type: actions.dashboard.SET_DATA,
            payload: data,
        });
    };
}

export function setSelectedAttr({field, practice, value}) {
    return async function (dispatch) {
        dispatch({
            type: actions.dashboard.SET_SELECTED_ATTR,
            payload: {field, practice, value},
        });
    };
}

export function setIsDemo(isDemo) {
    return async function (dispatch) {
        dispatch({
            type: actions.dashboard.SET_DATA,
            payload: {isDemo},
        });
    };
}
