import autobind from 'common/decorators/autobind.js';
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'utils/components/Icon.js';

export default class FormNotice extends React.Component {
    static propTypes = {
        dismissible: PropTypes.bool,
        errorType: PropTypes.oneOf(['error', 'warning', 'success']).isRequired,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
            .isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hide: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps && this.state.hide) {
            this.setState({hide: false});
        }
    }

    @autobind
    dismissNotice() {
        this.setState({
            hide: true,
        });
    }

    render() {
        if (this.state.hide) {
            return null;
        }
        const classStack = `alert alert-${this.props.errorType}`;
        return (
            <div className={classStack}>
                <Icon name="IconAlert" />
                <p data-test-id="form-notice-message">{this.props.message}</p>
                {this.props.dismissible && (
                    <button
                        className="dismiss"
                        data-test-id="form-notice-dismiss-button"
                        onClick={this.dismissNotice}
                        type="button"
                    >
                        <Icon name="IconReset" />
                    </button>
                )}
            </div>
        );
    }
}
