import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {
    CLINICAL_PROBLEMS_SEARCH_API,
    MEDICATIONS_SEARCH_API,
} from 'apiEndpoints.js';
import autocompleteManager from 'autocomplete_manager.js';
import {
    handleError,
    showNotification,
} from 'better_consult/actions/appActions.js';
import {messages} from 'better_consult/components/Notification.js';
import {
    DURATION_TIME_FRAME_YEARS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_HOURS,
} from 'clinical/helpers.js';
import {saveStore} from 'consultations/actions/consultationActions.js';
import {
    completeStep,
    setStepLoading,
    setStepReloaded,
    undoStep,
} from 'consultations/actions/formActions.js';

export const status = {
    STATUS_COMPLETE: 'C',
    STATUS_INCOMPLETE: 'I',
};

export const consultationFeatureFlags = {
    ADD_MEDICATION_STEP: 'ADD_MEDICATION_STEP',
    ASK_WALKIN_IF_EXISTING: 'ASK_WALKIN_IF_EXISTING',
    BODY_PART_LATERALISATION: 'BODY_PART_LATERALISATION_CONSULTATION',
    FREE_TEXT_PROBLEM_SEARCH: 'FREE_TEXT_PROBLEM_SEARCH',
    LANGUAGES_SELECT_US: 'LANGUAGES_SELECT_US',
    LANGUAGES_SELECT_AU: 'LANGUAGES_SELECT_AU',
    US_REVIEWS_OF_SYSTEMS: 'US_REVIEWS_OF_SYSTEMS',
    TRANSITION_FROM_PRESCRIPTION_TO_SYMPTOM_OR_CONDITION_FLOW:
        'TRANSITION_FROM_PRESCRIPTION_TO_SYMPTOM_OR_CONDITION_FLOW',
};

export const solutions = {
    WALK_IN: 'WALK_IN',
    INVITATION: 'INVITATION',
    NON_INTEGRATED_INVITATION: 'NON_INTEGRATED_INVITATION',
    PMS_INTEGRATED: 'PMS_INTEGRATED',
};

export const bodyPartLateralisation = {
    left: 'L',
    right: 'R',
    mid: 'M',
};

export function createStep({
    question,
    mapState = () => ({}),
    mapActions = () => ({}),
    props = {},
    reloadAction,
    submitAction,
    undoAction,
}) {
    if (!reloadAction) {
        throw new Error('A reload action must be provided');
    }
    return injectIntl(
        connect(
            function (store, ownProps) {
                return mapState(store, {...props, ...ownProps});
            },
            function (dispatch, ownProps) {
                const notification = {
                    primaryButton: {
                        action: 'reload',
                        text: ownProps.intl.formatMessage(messages.reload),
                    },
                    showOverlay: true,
                    secondaryButton: {
                        action: 'report',
                        text: ownProps.intl.formatMessage(messages.report),
                    },
                    title: ownProps.intl.formatMessage(messages.errorMessage),
                    type: 'error',
                };

                async function modifiedReloadAction() {
                    const submitData = dispatch(reloadAction(ownProps.id));
                    if (submitData) {
                        await modifiedSubmitAction(
                            {
                                stepId: ownProps.id,
                                props: ownProps,
                                ...submitData,
                            },
                            true,
                        );
                    }
                    dispatch(setStepReloaded(ownProps.id, true));
                    return submitData;
                }
                async function modifiedSubmitAction(
                    {stepId = ownProps.id, props = ownProps, ...args} = {},
                    reload = false,
                ) {
                    dispatch(setStepLoading(stepId, true));
                    if (submitAction) {
                        try {
                            await dispatch(
                                submitAction({stepId, props, ...args}, reload),
                            );
                        } catch (error) {
                            dispatch(showNotification(notification));
                            dispatch(handleError({error}));
                            return;
                        }
                    }
                    dispatch(completeStep(stepId));
                    if (!reload) {
                        dispatch(saveStore());
                    }
                }
                async function modifiedUndoAction({
                    stepId = ownProps.id,
                    props = ownProps,
                    ...args
                } = {}) {
                    dispatch(setStepLoading(stepId, true));
                    if (undoAction) {
                        try {
                            await dispatch(
                                undoAction({stepId, props, ...args}),
                            );
                        } catch (error) {
                            dispatch(showNotification(notification));
                            dispatch(handleError({error, isUndo: true}));
                            return;
                        }
                    }
                    dispatch(undoStep(stepId));
                    dispatch(saveStore());
                }
                return {
                    reloadAction: modifiedReloadAction,
                    submitAction: modifiedSubmitAction,
                    undoAction: modifiedUndoAction,
                    ...mapActions(dispatch, {...props, ...ownProps}),
                };
            },
            function (stateProps, dispatchProps, questionProps) {
                return {
                    ...props,
                    ...questionProps,
                    ...stateProps,
                    ...dispatchProps,
                };
            },
        )(question),
    );
}

const durationHoursMultiplier = {
    [DURATION_TIME_FRAME_YEARS.id]: 8760,
    [DURATION_TIME_FRAME_MONTHS.id]: 720,
    [DURATION_TIME_FRAME_WEEKS.id]: 168,
    [DURATION_TIME_FRAME_DAYS.id]: 24,
    [DURATION_TIME_FRAME_HOURS.id]: 1,
};

export function convertToHours(quantity, duration) {
    if (duration in durationHoursMultiplier) {
        return quantity * durationHoursMultiplier[duration];
    }
    return quantity;
}

const durationDaysMultiplier = {
    [DURATION_TIME_FRAME_YEARS.id]: 365,
    [DURATION_TIME_FRAME_MONTHS.id]: 30,
    [DURATION_TIME_FRAME_WEEKS.id]: 7,
    [DURATION_TIME_FRAME_DAYS.id]: 1,
};

export function convertToDays(quantity, duration) {
    if (duration in durationDaysMultiplier) {
        return quantity * durationDaysMultiplier[duration];
    }
    return quantity;
}

export function findPreviousProblemStep({form, stepType, stepId}) {
    const currentStep = form.steps.find(
        (step) => step.component.type === stepType && step.id === stepId,
    );
    if (!currentStep) {
        return;
    }
    const previousSteps = form.previousSteps.filter(
        (step) => step.component.type === stepType,
    );
    if (previousSteps.length) {
        const {problem} = currentStep.component.props;
        const {conditionId, freeTextProblemId, symptomId} = problem;
        if (conditionId) {
            return previousSteps.find(
                (step) =>
                    step.component.props.problem.conditionId === conditionId,
            );
        } else if (symptomId) {
            return previousSteps.find(
                (step) => step.component.props.problem.symptomId === symptomId,
            );
        } else if (freeTextProblemId) {
            return previousSteps.find(
                (step) =>
                    step.component.props.problem.freeTextProblemId ===
                    freeTextProblemId,
            );
        }
    }
}

export function findConditionRelatedSymptom(symptoms, symptomId) {
    return Object.values(symptoms).find(
        (symptom) =>
            symptom.id === symptomId &&
            symptom.presentingConsultationPastCondition,
    );
}

export async function searchMedications(q) {
    let results = [];
    if (q) {
        results = await autocompleteManager.getResults(
            MEDICATIONS_SEARCH_API,
            {q},
        );
        results = results.map((result) => ({
            id: result.id,
            text: result.name,
        }));
    }
    return results;
}

export async function searchProblems(q) {
    let results = [];
    const data = {
        q,
        filter: 'medication-problem',
    };
    if (q) {
        const url = CLINICAL_PROBLEMS_SEARCH_API;
        try {
            results = await autocompleteManager.getResults(url, data);
            results = results.map((result) => ({
                id: result.id,
                text: result.name,
                type: result.type,
            }));
        } catch (err) {
            throw err;
        }
    }
    return results;
}

export const FAQ_URL = {
    'AU':
        'https://au.betterconsult.support/hc/en-us/categories/360001414693-BetterConsult-for-Patients',
};
