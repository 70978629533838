import autobind from 'common/decorators/autobind.js';
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'utils/components/Icon.js';

export default class SelectInput extends React.Component {
    static propTypes = {
        ariaDescribedBy: PropTypes.string,
        ariaInvalid: PropTypes.bool,
        ariaLabel: PropTypes.string,
        autoFocus: PropTypes.bool,
        defaultOptionValue: PropTypes.string,
        disabled: PropTypes.bool,
        handleBlur: PropTypes.func,
        handleChange: PropTypes.func.isRequired,
        iconName: PropTypes.string,
        id: PropTypes.string,
        initialValue: PropTypes.string,
        inputName: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.array.isRequired,
    };

    static defaultProps = {
        ariaDescribedBy: null,
        autoFocus: false,
        disabled: false,
        id: null,
        initialValue: '',
        inputName: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue || props.defaultOptionValue || '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({value: this.props.initialValue});
        }
    }

    @autobind
    handleChangeInput(event) {
        const value = event.currentTarget.value;
        this.setState({value});
        this.props.handleChange(value, event);
    }

    renderSelect() {
        return (
            <select
                aria-describedby={this.props.ariaDescribedBy}
                aria-invalid={this.props.ariaInvalid}
                aria-label={this.props.ariaLabel}
                autoFocus={this.props.autoFocus}
                data-test-id="select-input"
                disabled={this.props.disabled}
                id={this.props.id}
                name={this.props.inputName}
                onBlur={this.props.handleBlur}
                onChange={this.handleChangeInput}
                required
                value={this.state.value}
                data-openreplay-obscured
            >
                {this.props.defaultOptionValue && (
                    <option
                        data-test-id="select-option"
                        disabled
                        value={this.props.defaultOptionValue}
                    >
                        {this.props.defaultOptionValue}
                    </option>
                )}
                {this.props.options.map((option) => (
                    <option
                        data-test-id="select-option"
                        key={option.id}
                        value={option.id}
                    >
                        {option.text}
                    </option>
                ))}
            </select>
        );
    }

    render() {
        if (this.props.iconName) {
            return (
                <label className="label-select">
                    <Icon name={this.props.iconName} />
                    {this.renderSelect()}
                </label>
            );
        } else if (this.props.label) {
            return (
                <label className="label-select">
                    {this.props.label}
                    {this.renderSelect()}
                </label>
            );
        } else {
            return this.renderSelect();
        }
    }
}
