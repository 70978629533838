import React from 'react';
import PropTypes from 'prop-types';

export default class InputNotice extends React.Component {
    static propTypes = {
        additionalClass: PropTypes.string,
        errorId: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        noticeRef: PropTypes.object,
    };

    render() {
        const {additionalClass, errorId, message, noticeRef} = this.props;

        let classList = 'notice';
        if (additionalClass) {
            classList += ` ${additionalClass}`;
        }
        return (
            <span
                className={classList}
                data-test-id="input-notice"
                id={errorId}
                ref={noticeRef}
                role="alert"
            >
                {message}
            </span>
        );
    }
}
