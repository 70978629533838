import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {showModal} from 'better_consult/actions/appActions.js';
import consultationRoutes from 'consultations/routes.json';
import LinkWithPreservedQueryString from 'core/components/LinkWithPreservedQueryString.js';
import ProgressBar from 'core/components/ProgressBar.js';
import dashboardRoutes from 'dashboard/routes.json';
import query from 'query.js';
import baseRoutes from 'routes.json';
import supportRoutes from 'support/routes.json';
import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    back: {
        id: 'core.MenuBar.back',
        defaultMessage: 'back',
    },
    betterConsult: {
        id: 'core.MenuBar.betterConsult',
        defaultMessage: 'betterConsult',
    },
    feedback: {
        id: 'core.MenuBar.feedback',
        defaultMessage: 'feedback',
    },
    login: {
        id: 'core.MenuBar.login',
        defaultMessage: 'login',
    },
    logout: {
        id: 'core.MenuBar.logout',
        defaultMessage: 'logout',
    },
});

export class MenuBar extends React.Component {
    static propTypes = {
        additionalClass: PropTypes.string,
        feedbackButtonType: PropTypes.oneOf(['mail', 'modal']),
        handleBack: PropTypes.func,
        menuHeader: PropTypes.string,
        menuType: PropTypes.oneOf([
            'accounts',
            'consultation',
            'consultationSuccess',
        ]).isRequired,
        progressValue: PropTypes.number,
        setMenuBarRef: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.intl = props.intl;
        this.zone = document.body.dataset.zone;
        this.state = {
            accounts: [],
            consultation: [],
            consultationSuccess: [{component: 'Logout'}],
            sid: query.parse().sid,
        };
    }

    @autobind
    showFeedbackModal(event) {
        event.preventDefault();
        this.props.showModal('FEEDBACK_MODAL');
    }

    renderFeedbackButton() {
        if (this.props.feedbackButtonType === 'mail') {
            const hrefPath =
                'mailto:support@betterconsult.com?' +
                'subject=BetterConsult Feedback';
            return (
                <a
                    className="cta"
                    data-test-id="menu-feedback-button"
                    href={hrefPath}
                >
                    {this.intl.formatMessage(messages.feedback)}
                </a>
            );
        } else {
            return (
                <button
                    className="cta"
                    data-test-id="menu-feedback-button"
                    onClick={this.showFeedbackModal}
                    type="button"
                >
                    {this.intl.formatMessage(messages.feedback)}
                </button>
            );
        }
    }

    renderLinks(menuType) {
        const routeData = [
            ...baseRoutes,
            ...consultationRoutes,
            ...dashboardRoutes,
            ...supportRoutes,
        ];
        let links = this.state[menuType];
        // only show logout link if user is not a guest
        if (this.props.isGuestWorkflow && menuType === 'consultationSuccess') {
            links = links.filter((link) => link.component !== 'Logout');
        }
        const menuLinks = links.map((link) =>
            routeData.find((route) => {
                if (link.params) {
                    Object.entries(link.params).forEach(([param, value]) => {
                        route.react = route.react.replace(`:${param}?`, value);
                    });
                }
                return route.component === link.component;
            }),
        );
        return menuLinks.map((link) => {
            const currentPage = link.component.toLowerCase();
            // eslint-disable-next-line max-len
            const toPath = this.state.sid
                ? `${link.react}?sid=${this.state.sid}`
                : link.react;
            return (
                <LinkWithPreservedQueryString
                    aria-selected={false}
                    data-test-id={`menu-${currentPage}-link`}
                    key={currentPage}
                    preservedQueryKeys={['token', 'sid']}
                    to={toPath}
                >
                    {this.intl.formatMessage(messages[currentPage])}
                </LinkWithPreservedQueryString>
            );
        });
    }

    renderProgressBar() {
        if (this.props.progressValue >= 0) {
            return <ProgressBar currentValue={this.props.progressValue} />;
        }
    }

    render() {
        const {additionalClass, setMenuBarRef} = this.props;
        let classList = 'menu';
        if (additionalClass) {
            classList += ` ${additionalClass}`;
        }
        return (
            <header
                className={classList}
                data-test-id="menu-header"
                ref={(node) => {
                    if (setMenuBarRef) {
                        setMenuBarRef(node);
                    }
                }}
            >
                <nav>
                    {this.props.handleBack && (
                        <button
                            className="cta"
                            data-test-id="menu-back-button"
                            onClick={this.props.handleBack}
                            type="button"
                        >
                            {this.intl.formatMessage(messages.back)}
                        </button>
                    )}
                    {this.props.menuHeader ? (
                        <h4 data-test-id="menu-header-title">
                            {this.props.menuHeader}
                        </h4>
                    ) : (
                        <div className="mark">
                            <Icon name="IconLogo" />
                            <span data-test-id="menu-header-title">
                                {this.intl.formatMessage(
                                    messages.betterConsult,
                                )}
                            </span>
                        </div>
                    )}
                    <nav className="tabs">
                        {this.props.feedbackButtonType
                            ? this.renderFeedbackButton()
                            : this.renderLinks(this.props.menuType)}
                    </nav>
                </nav>
                {this.renderProgressBar()}
            </header>
        );
    }
}

function mapStateToProps(store) {
    const {consultation} = store.data.consultations;
    return {
        client: store.app.client,
        isGuestWorkflow:
            !consultation.hasUser ||
            (consultation.hasGuestUser && !consultation.claimed),
        token: consultation.token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        showModal: (modalType) => dispatch(showModal(modalType)),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(MenuBar),
);
