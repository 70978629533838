import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import MenuBar from 'core/components/MenuBar.js';

const messages = defineMessages({
    headerText: {
        id: 'consultations.InactiveConsultation.headerText',
        defaultMessage: 'headerText',
    },
    noActiveConsultations: {
        id: 'consultations.InactiveConsultation.noActiveConsultations',
        defaultMessage: 'noActiveConsultations',
    },
    sendFeedback: {
        id: 'consultations.InactiveConsultation.sendFeedback',
        defaultMessage: 'sendFeedback',
    },
});

/*
    Clinical:
    N/A

    Technical:
    This step is shown when a patient hits /consultations/ without an active
    consultation.

    Dependencies:
    N/A

    Future:
    - Ideally we don't have this. It should redirect to a profile/settings page
*/

export class InactiveConsultation extends React.Component {
    render() {
        const intl = this.props.intl;
        return (
            <React.Fragment>
                <MenuBar
                    additionalClass="menu-consultation"
                    feedbackButtonType="mail"
                    menuType="consultation"
                />
                <section className="question current viewed">
                    <div
                        className="text-block"
                        data-test-id="inactive-consultation-message"
                    >
                        <h1>
                            <strong>
                                {intl.formatMessage(messages.headerText)}
                            </strong>
                        </h1>
                        <p>
                            {intl.formatMessage(
                                messages.noActiveConsultations,
                            )}
                        </p>
                        <p>{intl.formatMessage(messages.sendFeedback)}</p>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default injectIntl(InactiveConsultation);
